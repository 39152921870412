import React from 'react'
import { Router } from '@reach/router'
import Preview from './Preview'

const ArticlePreview = () => (
  <Router>
    <Preview path="/blog/preview" />
  </Router>
)

export default ArticlePreview
