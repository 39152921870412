import React from 'react'
import PropTypes from 'prop-types'
import sanityClient from '@sanity/client'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import unwrapImages from 'remark-unwrap-images'
import { COLOR } from 'Theme'
import { fullWidthStyle } from 'components/mixins'
import { Box } from 'components/Layout'
import GenericPage from 'components/GenericPage'
import SEO from 'components/SEO'
import ProfileBlock from 'routes/components/ProfileBlock'
import { CodeBlock, InlineCode } from '../components/markdownRenderers'
import Hero from '../components/Hero'
import PostContainer from '../components/PostContainer'
import { ArticleStyle, Title, Description } from '../Article'

const client = sanityClient({
  projectId: '479ag3nz',
  dataset: 'production',
  useCdn: false,
  withCredentials: true,
})

const sanityQuery = `
  *[_id == $articleId]{
    markdownInput,
    title,
    description,
    author->{name, bio, socialNetworks, 'image': image.asset->url},
  }
`

const Preview = ({ location }) => {
  const [previewArticle, setPreviewArticle] = React.useState()

  React.useEffect(() => {
    const { id: articleId } = queryString.parse(location.search)
    const fetchPreview = async () => {
      try {
        const [article] = await client.fetch(sanityQuery, { articleId })
        if (!article) {
          navigate('/')
        } else {
          setPreviewArticle(article)
        }
      } catch (error) {
        navigate('/')
      }
    }
    fetchPreview()
  }, [location.search])

  return (
    <GenericPage>
      {previewArticle && (
        <>
          <SEO title={previewArticle.title} />
          <Hero>
            <Title>{previewArticle.title}</Title>
            <Description>{previewArticle.description}</Description>
            <ProfileBlock
              name={previewArticle.author.name}
              avatar={
                <img
                  src={previewArticle.author.image}
                  alt={previewArticle.author.name}
                />
              }
              socialNetworks={previewArticle.author.socialNetworks}
            />
          </Hero>
          <Box css={fullWidthStyle} backgroundColor={COLOR.WHITE}>
            <PostContainer>
              <ArticleStyle>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={previewArticle.markdownInput}
                  rehypePlugins={[rehypeRaw]}
                  linkTarget="_blank"
                  plugins={[unwrapImages]}
                  components={{
                    code: CodeBlock,
                    pre: InlineCode,
                  }}
                />
              </ArticleStyle>
            </PostContainer>
          </Box>
        </>
      )}
    </GenericPage>
  )
}

Preview.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default Preview
